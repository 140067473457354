import { FormError } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import { sleep } from "@/helpers";
import { getDeviceInfo } from "@/helpers/device";
import { graphQLErrorCode } from "@/helpers/format";
import { getClientOrigin, hasIdentity, setIdentity } from "@/helpers/identity";
import { assignPathWith, getParams } from "@/helpers/navigation";
import error from "@/pages/error";
import { gql, useMutation } from "@apollo/client";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SIGNUP_WIZARD = gql`
  mutation WorkspaceQuickSignup(
    $linkDestination: String!
    $originInput: OriginInput!
    $deviceInput: DeviceInput!
  ) {
    WorkspaceQuickSignup(
      linkDestination: $linkDestination
      originInput: $originInput
      deviceInput: $deviceInput
    ) {
      id
      role
      accessToken
      workspace {
        id
        primaryLink {
          id
        }
      }
    }
  }
`;

import PartialLoading from "@/components/partial-loading";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

export function WorkspaceAlreadyRegistered() {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/authentication/sign-in";
    }, 3000);
  }, []);

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="bg-white p-3 rounded-xl mt-10 shadow-md"
    >
      <Box
        sx={{
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <DoNotDisturbIcon />
        </Avatar>
        <Typography component="h1" variant="h5" textAlign={"center"}>
          This URL has already been claimed!
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <FormError error={error} sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign={"center"}>
              If you're the owner of this URL, please sign in. You'll now be
              redirected.
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <PartialLoading />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export function PreparingWorkspace() {
  return (
    <Container
      component="main"
      maxWidth="xs"
      className="bg-white p-3 rounded-xl mt-10 shadow-md"
    >
      <Box
        sx={{
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <FormError error={error} sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign={"center"}>
              We are preparing your workspace...
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <PartialLoading />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

import QrCodeIcon from "@mui/icons-material/QrCode";

export default function SignUpWizard() {
  const { t } = useTranslation("workspace");
  const navigate = useNavigate();

  const params = getParams();
  const [formData, setFormData] = useState({
    url: params.get("url") || "",
  });
  const skipDesign = params.get("skip_design") === "true";

  const [mutationSignUp, { data, loading, error }] = useMutation(SIGNUP_WIZARD);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    // we recover tall the data that could have been transmitted
    // from the landings if the person was on a landing
    const originInput = getClientOrigin();
    const deviceInput = getDeviceInfo();

    mutationSignUp({
      variables: {
        linkDestination: formData.url,
        deviceInput,
        originInput,
      },
    });
  };

  const formCompleted = formData.url.length > 0;
  const disabled = !formCompleted || loading;

  useEffect(() => {
    if (data) {
      setIdentity({
        id: data.WorkspaceQuickSignup.id,
        accessToken: data.WorkspaceQuickSignup.accessToken,
        role: data.WorkspaceQuickSignup.role,
        workspaceID: data.WorkspaceQuickSignup.workspace.id,
      });

      (async () => {
        while (hasIdentity() !== true) await sleep(500);
        // to skip design and just go to the dashboard
        if (skipDesign) {
          assignPathWith(navigate, "/workspace/dashboard/live");
        } else {
          const linkID = data.WorkspaceQuickSignup.workspace.primaryLink.id;
          assignPathWith(
            navigate,
            `/workspace/links/${linkID}/design?primary=true`
          );
        }
      })();
    }
  }, [data]);

  useEffect(() => {
    // NOTE: can't work because non guest are not authorized to access this area
    // could be changed in the future
    // if (!isGuest()) {
    //   if (formData.url) {
    //     const destination = formData.url;
    //     window.location.href = `/workspace/links/create?primary=false&destination=${destination}`;
    //   } else {
    //     window.location.href = "/";
    //   }
    // }

    if (params.get("url")) {
      // we recover tall the data that could have been transmitted
      // from the landings if the person was on a landing
      const originInput = getClientOrigin();
      const deviceInput = getDeviceInfo();

      mutationSignUp({
        variables: {
          linkDestination: formData.url,
          deviceInput,
          originInput,
        },
      });
    }
  }, []);

  if (error) {
    if (graphQLErrorCode(error, "WORKSPACE_ALREADY_REGISTERED")) {
      return <WorkspaceAlreadyRegistered />;
    }
  }

  if (loading || data || params.get("url")) {
    return <PreparingWorkspace />;
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="bg-white p-3 rounded-xl mt-10 shadow-md"
    >
      <Box
        sx={{
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <QrCodeIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("sign-up.title-qr-code")}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <FormError error={error} sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="url"
                type="url"
                label={t("sign-up.url")}
                name="url"
                autoFocus
                value={formData.url}
                onChange={handleChange}
                autoComplete="url"
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={loading}
            disabled={disabled}
            onClick={handleSubmit}
            text={t("sign-up.create-qr-code")}
            fullWidth
          />
        </Box>
      </Box>
    </Container>
  );
}
