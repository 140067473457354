import AstroQuestion from "@/components/astro-question";
import { eventBusEmit } from "@/helpers/event-bus";
import { capitalizeFirst, removeHiglightAfter } from "@/helpers/format";
import { Event, Member } from "@/interfaces";
import CheckIcon from "@mui/icons-material/Check";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import React from "react";
import { useTranslation } from "react-i18next";

export function ActionDisplay({ event }) {
  const { t } = useTranslation("workspace");
  let color;
  let action: string = "";

  color = "error";

  if (event.action === "SCAN") {
    color = "primary";
    action = capitalizeFirst(t(`events.action.scan`));
  }

  const warning = false;
  const icon = <CheckIcon color={color} />;
  const tooltip = t("events.visitor-scan-tooltip");

  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Chip
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
        label={action}
        color={warning ? color : "default"}
        icon={icon}
      />
    </Tooltip>
  );
}

import QrCodeIcon from "@mui/icons-material/QrCode";

export function LinkCell({ event }: { event: Event }) {
  const { t, i18n } = useTranslation("workspace");

  let color;
  const icon: JSX.Element = <QrCodeIcon />;
  const tooltip: string = "QR Code that was scanned by the visitor.";

  return (
    <React.Fragment>
      <Tooltip title={tooltip} placement="top" arrow>
        <Chip
          color={color}
          icon={icon}
          label={event.link.name || event.link.destination}
        />
      </Tooltip>
    </React.Fragment>
  );
}

import { GridWithSearch } from "@/components/data-table";
import { relativeDate } from "@/helpers/time";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { parseISO } from "date-fns";
import { DeviceChip } from "../members/devices/list";

export function ListEvents({ events }: { events: Event[] }) {
  const { i18n, t } = useTranslation(["workspace", "misc"]);

  React.useEffect(() => {
    if (events.length > 0) {
      removeHiglightAfter(2100);

      // this is when it starts appearing
      if (events.length >= 2) {
        eventBusEmit({ type: "menu-items", payload: "show-reports" });
      }
    }
  }, [events]);

  if (events.length === 0)
    return (
      <Stack alignItems="center" textAlign="center">
        <Grid item xs={4} lg={3} sx={{ mb: 2 }}>
          <AstroQuestion />
          <Typography>
            <span
              dangerouslySetInnerHTML={{
                __html: t("events.please-scan-qr-codes", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </Typography>
        </Grid>
      </Stack>
    );

  const RECENT_IN_SECONDS = 60; // in seconds
  const recentEvent = new Date().setSeconds(
    new Date().getSeconds() - RECENT_IN_SECONDS
  );

  const columns: GridColDef[] = [
    {
      field: "date",
      flex: 1,
      headerName: t("events.table-date"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return capitalizeFirst(relativeDate(params.row.scannedAt, i18n));
      },
      valueGetter: (_, row) => {
        return relativeDate(row.scannedAt, i18n);
      },
      cellClassName(params) {
        const isRecent = new Date(recentEvent) < parseISO(params.row.scannedAt);
        return isRecent ? "highlightedRow" : "";
      },
    },
    {
      field: "action",
      flex: 1,
      headerName: t("events.table-action"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ActionDisplay event={params.row} />;
      },
      valueGetter: (_, row) => {
        return row.action;
      },
      cellClassName(params) {
        const isRecent = new Date(recentEvent) < parseISO(params.row.scannedAt);
        return isRecent ? "highlightedRow" : "";
      },
    },
    {
      field: "link",
      flex: 1,
      headerName: t("events.table-link"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <LinkCell event={params.row} />;
      },
      valueGetter: (_, row) => {
        return row.destination;
      },
      cellClassName(params) {
        const isRecent = new Date(recentEvent) < parseISO(params.row.scannedAt);
        return isRecent ? "highlightedRow" : "";
      },
    },
    {
      field: "device",
      flex: 1,
      headerName: t("events.table-device"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <DeviceChip device={params.row.device} tooltip={true} />;
      },
      valueGetter: (_, row) => {
        return row.device?.id;
      },
      cellClassName(params) {
        const isRecent = new Date(recentEvent) < parseISO(params.row.scannedAt);
        return isRecent ? "highlightedRow" : "";
      },
    },
  ];

  const rows = events;
  return (
    <GridWithSearch columns={columns} rows={rows} i18n={i18n} rowHeight={55} />
  );
}

// <20 = GOOD
// <50 = AVERAGE
// >100 = BAD

export default function Events({ events }: { events: Event[] }) {
  return (
    <React.Fragment>
      <ListEvents events={events} />
    </React.Fragment>
  );
}
