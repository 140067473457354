import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { eventBusEmit } from "@/helpers/event-bus";
import Title from "@/pages/workspace/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone-esm";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const GET_LINK = gql`
  query GetLink($id: UUID!) {
    GetLink(id: $id) {
      id
      destination
      designTemplate {
        id
        imageSignedURL
        imageFormat
        imageSize
        dotsOptions {
          color
          type
        }
        cornersSquareOptions {
          color
          type
        }
        cornersDotOptions {
          color
          type
        }
        backgroundOptions {
          color
        }
        shape
        width
        height
        errorCorrectionLevel
        hideBackgroundDots
      }
    }
  }
`;

const UPSERT_LINK_DESIGN_TEMPLATE = gql`
  mutation UpsertLinkDesignTemplate(
    $linkID: UUID!
    $linkQRCode: Upload!
    $designTemplateInput: DesignTemplateInput!
  ) {
    UpsertLinkDesignTemplate(
      linkID: $linkID
      linkQRCode: $linkQRCode
      designTemplateInput: $designTemplateInput
    ) {
      id
    }
  }
`;

export default function DesignLink() {
  const { linkID } = useParams();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Title>Design QR Code</Title>

            <Divider variant="middle" sx={{ mb: 2 }} />

            <QRCodeDesign style="containers" linkID={linkID} />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export function QRCodeDesign({
  linkID,
  style = "containers",
  callback = () => {},
}) {
  const { t } = useTranslation(["workspace", "misc"]);
  const navigate = useNavigate();
  const [, setID] = useState<string>("");
  const [getBlob, setGetBlob] = useState<boolean>(false);
  const [blobValue, setBlobValue] = useState<Blob | null>(null);
  const [designTemplateInput, setDesignTemplateInput] = useState<any>({});
  const [target, setTarget] = useState<string>(DEFAULT_QRCODE_URL);
  const getLink = useQuery(GET_LINK, {
    variables: { id: linkID },
    fetchPolicy: "no-cache",
  });
  const [mutationUpsertDesignTemplate, { data, error, loading }] = useMutation(
    UPSERT_LINK_DESIGN_TEMPLATE
  );

  let containerClass = "h-fit p-5";
  if (style === "containers") {
    containerClass =
      "h-fit p-5 mt-10 border-verylightgrey border-solid border-2 border-greyish bg-white rounded-lg shadow-md";
  }

  useEffect(() => {
    const possibleErrors = error;
    const errorDisplay: string = findAndTranslateErrors({
      error: possibleErrors,
      t,
    });
    let successDisplay: string = "";
    if (data) {
      successDisplay = t("links.edit.updated");
      callback();
    }
    if (successDisplay) {
      eventBusEmit({ type: "form-success", payload: successDisplay });
    }
    if (errorDisplay) {
      eventBusEmit({ type: "form-error", payload: errorDisplay });
    }
  }, [t, data, error]);

  React.useEffect(() => {
    if (getLink.data) {
      setID(getLink.data.GetLink.id);
      setTarget(entrypointURL(getLink.data.GetLink));
      if (getLink.data.GetLink.name) {
        const pageName: string = getLink.data.GetLink.name;
        eventBusEmit({ type: "page-name", payload: pageName });
      }
    }
  }, [getLink.data, navigate, t]);

  React.useEffect(() => {
    if (blobValue) {
      // We have to do that because the input is a string
      // So we prepare them before sending it over network
      const dotsOptions = JSON.stringify(designTemplateInput.dotsOptions);
      const cornersSquareOptions = JSON.stringify(
        designTemplateInput.cornersSquareOptions
      );
      const cornersDotOptions = JSON.stringify(
        designTemplateInput.cornersDotOptions
      );
      const backgroundOptions = JSON.stringify(
        designTemplateInput.backgroundOptions
      );

      const filename = `qrcode.${blobToExtension(blobValue)}`;
      const linkQRCode = new File([blobValue], filename, {
        type: blobValue.type,
      });

      if (designTemplateInput.image) {
        fetch(designTemplateInput.image)
          .then((res) => res.blob())
          .then((blob) => {
            const filename = `image.${blobToExtension(blob)}`;
            const image = new File([blob], filename, { type: blob.type });

            mutationUpsertDesignTemplate({
              variables: {
                linkID,
                linkQRCode,
                designTemplateInput: {
                  ...designTemplateInput,
                  dotsOptions,
                  cornersSquareOptions,
                  cornersDotOptions,
                  backgroundOptions,
                  image,
                },
              },
            });
            setBlobValue(null);
          });
      } else {
        // We don't want to upload an empty string
        designTemplateInput.image = null;
        mutationUpsertDesignTemplate({
          variables: {
            linkID,
            linkQRCode,
            designTemplateInput: {
              ...designTemplateInput,
              dotsOptions,
              cornersSquareOptions,
              cornersDotOptions,
              backgroundOptions,
            },
          },
        });
        setBlobValue(null);
      }
    }
  }, [blobValue]);

  if (getLink.loading) return <Loading />;
  if (getLink.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    // We will try to get the blob from the QrGen
    // It'll then run the blobCallback once we have it
    setGetBlob(true);
  };

  const blobCallback = (blob) => {
    setBlobValue(blob);
    setGetBlob(false);
  };

  // it's loading and we don't have the link data yet
  // so we don't show the design
  if (!getLink.data && !getLink.error) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Stack direction={{ xs: "column", md: "row" }} sx={{ mb: 2 }} spacing={2}>
        <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
          <QRCodeGenerator
            target={target}
            getBlob={getBlob}
            containerClass={containerClass}
            blobCallback={blobCallback}
            designTemplate={getLink.data.GetLink.designTemplate}
            setDesignTemplateInput={setDesignTemplateInput}
          />
        </Grid>
      </Stack>

      <Divider variant="middle" sx={{ mb: 2 }} />

      <Grid
        item
        xs={12}
        md={6}
        lg={3}
        sx={{ textAlign: "center", margin: "auto" }}
      >
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={handleSubmit}
          text="Save design"
          fullWidth
        />
      </Grid>
    </React.Fragment>
  );
}

export function QRCodeGenerator({
  target,
  getBlob,
  blobCallback,
  designTemplate,
  containerClass,
  setDesignTemplateInput,
}) {
  const DEFAULT_EXTENSION = "SVG";
  const DEFAULT_PRESET = "classic";
  const DEFAULT_QRCODE_IMAGE = "";
  const DEFAULT_IMAGE_SIZE = 0.6;
  const DEFAULT_SIZE = 512;
  const DEFAULT_ERROR_CORRECTION_LEVEL = "Q";
  const DEFAULT_DOTS_OPTIONS = {
    color: "#0e3374",
    type: "dots",
  };
  const DEFAULT_CORNERS_SQUARE_OPTIONS = {
    color: "#000000",
    type: "square",
  };
  const DEFAULT_CORNERS_DOT_OPTIONS = {
    color: "#7e81f8",
    type: "dot",
  };
  const BACKGROUND_OPTIONS = {
    color: "#FFFFFFF",
  };
  const DEFAULT_SHAPE = "square";

  // We don't set any preset if the design template is present
  // Because it'll load
  const [preset, setPreset] = useState<string>(
    designTemplate ? "" : DEFAULT_PRESET
  );

  const [imageFormat, setImageFormat] = useState<string>(
    designTemplate?.imageFormat || DEFAULT_EXTENSION
  );

  // input on the corner
  const [inputImage, setInputImage] = useState<File | undefined>(undefined);
  const [image, setImage] = useState<string>(DEFAULT_QRCODE_IMAGE);
  const [imageSize, setImageSize] = useState<number>(
    designTemplate?.imageSize || DEFAULT_IMAGE_SIZE
  );
  const [hideBackgroundDots, setHideBackgroundDots] = useState<boolean>(
    designTemplate?.hideBackgroundDots || true
  );
  const [height, setHeight] = useState<number>(
    designTemplate?.height || DEFAULT_SIZE
  );
  const [width, setWidth] = useState<number>(
    designTemplate?.width || DEFAULT_SIZE
  );
  const [size, setSize] = React.useState<number>(
    designTemplate?.height || DEFAULT_SIZE
  );
  const [errorCorrectionLevel, setErrorCorrectionLevel] =
    useState<ErrorCorrectionLevel>(
      designTemplate?.errorCorrectionLevel || DEFAULT_ERROR_CORRECTION_LEVEL
    );
  const [dotsOptions, setDotsOptions] = useState<{
    color?: string;
    gradient?: string;
    type: DotType;
  }>(designTemplate?.dotsOptions || DEFAULT_DOTS_OPTIONS);
  const [cornersSquareOptions, setCornersSquareOptions] = useState<{
    color?: string;
    gradient?: string;
    type: CornerSquareType;
  }>(designTemplate?.cornersSquareOptions || DEFAULT_CORNERS_SQUARE_OPTIONS);
  const [cornersDotOptions, setCornersDotOptions] = useState<{
    color?: string;
    gradient?: string;
    type: CornerDotType;
  }>(designTemplate?.cornersDotOptions || DEFAULT_CORNERS_DOT_OPTIONS);
  const [backgroundOptions, setBackgroundOptions] = useState<{
    color?: string;
    gradient?: string;
  }>(designTemplate?.backgroundOptions || BACKGROUND_OPTIONS);
  const [shape, setShape] = useState<"SQUARE" | "CIRCLE">(
    designTemplate?.shape || DEFAULT_SHAPE
  );

  useEffect(() => {
    if (designTemplate?.imageSignedURL) {
      fetch(designTemplate.imageSignedURL)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png", { type: blob.type });
          setInputImage(file);
        });
    }
  }, [designTemplate?.imageSignedURL]);

  // Every detail will be set up to be
  // transmitted to the backend upon saving
  useEffect(() => {
    setDesignTemplateInput({
      imageFormat,
      image,
      imageSize,
      dotsOptions,
      cornersSquareOptions,
      cornersDotOptions,
      backgroundOptions,
      shape,
      width,
      height,
      errorCorrectionLevel,
      hideBackgroundDots,
    });
  }, [
    setDesignTemplateInput,
    imageFormat,
    image,
    imageSize,
    dotsOptions,
    cornersSquareOptions,
    cornersDotOptions,
    backgroundOptions,
    shape,
    width,
    height,
    errorCorrectionLevel,
    hideBackgroundDots,
  ]);

  useEffect(() => {
    setWidth(size);
    setHeight(size);
  }, [size]);

  useEffect(() => {
    switch (preset) {
      case "branded":
        setDotsOptions({
          color: "#0e3374",
          type: "dots",
        });
        setCornersSquareOptions({
          color: "#000000",
          type: "square",
        });
        setCornersDotOptions({
          color: "#7e81f8",
          type: "dot",
        });
        setBackgroundOptions({
          color: "transparent",
        });
        setShape("CIRCLE");
        if (!image) {
          fetch("/static-logo.png")
            .then((res) => res.blob())
            .then((blob) => {
              const logoFile = new File([blob], "static-logo.png", {
                type: blob.type,
              });
              setInputImage(logoFile);
            });
        }
        break;
      case "classic":
        setDotsOptions({
          color: "#000000",
          type: "square",
        });
        setCornersSquareOptions({
          color: "#000000",
          type: "square",
        });
        setCornersDotOptions({
          color: "#000000",
          type: "square",
        });
        setBackgroundOptions({
          color: "transparent",
        });
        setShape("SQUARE");
        if (image === DEFAULT_QRCODE_IMAGE) setImage("");
        break;
      case "rounded":
        setDotsOptions({
          color: "#000000",
          type: "extra-rounded",
        });
        setCornersSquareOptions({
          color: "#000000",
          type: "extra-rounded",
        });
        setCornersDotOptions({
          color: "#000000",
          type: "dot",
        });
        setBackgroundOptions({
          color: "transparent",
        });
        setShape("CIRCLE");
        if (image === DEFAULT_QRCODE_IMAGE) setImage("");
        break;
      case "dotted":
        setDotsOptions({
          color: "#000000",
          type: "dots",
        });
        setCornersSquareOptions({
          color: "#000000",
          type: "dot",
        });
        setCornersDotOptions({
          color: "#000000",
          type: "dot",
        });
        setBackgroundOptions({
          color: "transparent",
        });
        setShape("SQUARE");
        if (image === DEFAULT_QRCODE_IMAGE) setImage("");
        break;
      default:
        break;
    }
  }, [preset]);

  return (
    <div className="grid p-3">
      <div className="justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-12">
          <div
            className="h-fit grid grid-cols-1"
            // style={{ borderRight: "2px solid red" }}
          >
            <QrCodeImage
              target={target}
              getBlob={getBlob}
              blobCallback={blobCallback}
              containerClass={containerClass}
              imageFormat={imageFormat}
              image={image}
              imageSize={imageSize}
              dotsOptions={dotsOptions}
              cornersSquareOptions={cornersSquareOptions}
              cornersDotOptions={cornersDotOptions}
              backgroundOptions={backgroundOptions}
              shape={shape}
              width={width}
              height={height}
              errorCorrectionLevel={errorCorrectionLevel}
              hideBackgroundDots={hideBackgroundDots}
            />
            <div className={containerClass}>
              <QualitySettings
                setSize={setSize}
                size={size}
                setImageFormat={setImageFormat}
                imageFormat={imageFormat}
                setErrorCorrectionLevel={setErrorCorrectionLevel}
                errorCorrectionLevel={errorCorrectionLevel}
              />
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-1">
            <div className={containerClass}>
              <Presets setPreset={setPreset} preset={preset} />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className={containerClass}>
                <ShapeSettings setShape={setShape} shape={shape} />
                <DotsSettings
                  setDotsOptions={setDotsOptions}
                  dotsOptions={dotsOptions}
                />
                <CornersBorderSettings
                  setCornersSquareOptions={setCornersSquareOptions}
                  cornersSquareOptions={cornersSquareOptions}
                  setCornersDotOptions={setCornersDotOptions}
                  cornersDotOptions={cornersDotOptions}
                />
                <BackgroundSettings
                  setBackgroundOptions={setBackgroundOptions}
                  backgroundOptions={backgroundOptions}
                />
              </div>
              <div className="grid grid-cols-1">
                <div className={containerClass}>
                  <ImageSettings
                    setImage={setImage}
                    image={image}
                    inputImage={inputImage}
                    setInputImage={setInputImage}
                    setImageSize={setImageSize}
                    imageSize={imageSize}
                    setHideBackroundDots={setHideBackgroundDots}
                    hideBackgroundDots={hideBackgroundDots}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function QrCodeImage({
  target,
  getBlob,
  blobCallback,
  imageOnly = false,
  containerClass,
  imageFormat,
  image,
  imageSize,
  dotsOptions,
  cornersSquareOptions,
  cornersDotOptions,
  backgroundOptions,
  shape,
  width,
  height,
  errorCorrectionLevel,
  hideBackgroundDots,
}) {
  const QrImage = () => {
    return (
      <QrCode
        target={target}
        getBlob={getBlob}
        blobCallback={blobCallback}
        imageFormat={imageFormat}
        image={image}
        imageSize={imageSize}
        dotsOptions={dotsOptions}
        cornersSquareOptions={cornersSquareOptions}
        cornersDotOptions={cornersDotOptions}
        backgroundOptions={backgroundOptions}
        shape={shape}
        width={width}
        height={height}
        errorCorrectionLevel={errorCorrectionLevel}
        hideBackgroundDots={hideBackgroundDots}
      />
    );
  };

  if (imageOnly) {
    return <QrImage />;
  }

  return (
    <div className={containerClass}>
      <div className="mt-2 mb-2 center">
        <div className="m-auto text-center">
          <QrImage />
        </div>
      </div>
    </div>
  );
}

export function CornersBorderSettings({
  cornersSquareOptions,
  setCornersSquareOptions,
  cornersDotOptions,
  setCornersDotOptions,
}) {
  const { t } = useTranslation("workspace");

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Corners
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        {/* Outer */}
        <Grid item xs={12} md={8}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="corner-square-type"
            label={t("design.outer-shape")}
            value={cornersSquareOptions.type}
            onChange={(event) => {
              const type = event.target.value as DotType;
              setCornersSquareOptions({
                color: cornersSquareOptions.color,
                type,
              });
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="square">{t("design.square")}</option>
            <option value="dot">{t("design.dot")}</option>
            <option value="extra-rounded">{t("design.extra-rounded")}</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type="color"
            fullWidth
            variant="outlined"
            id="corner-square-color"
            label={t("design.color")}
            value={cornersSquareOptions.color}
            onChange={(event) => {
              const color = event.target.value;
              setCornersSquareOptions({
                color,
                type: cornersSquareOptions.type,
              });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* Inside */}
        <Grid item xs={12} md={8}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="corners-dot-options"
            label={t("design.inner-shape")}
            value={cornersDotOptions.type}
            onChange={(event) => {
              const type = event.target.value as DotType;
              setCornersDotOptions({
                color: cornersDotOptions.color,
                type,
              });
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="square">{t("design.square")}</option>
            <option value="dot">{t("design.dot")}</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type="color"
            fullWidth
            variant="outlined"
            id="corners-dot-color"
            label={t("design.color")}
            value={cornersDotOptions.color}
            onChange={(event) => {
              const color = event.target.value;
              setCornersDotOptions({
                color,
                type: cornersDotOptions.type,
              });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export function BackgroundSettings({
  backgroundOptions,
  setBackgroundOptions,
}) {
  const { t } = useTranslation("workspace");

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Background
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField
            type="color"
            fullWidth
            variant="outlined"
            id="background-color"
            label={t("design.color")}
            value={backgroundOptions.color}
            onChange={(event) => {
              const color = event.target.value;
              setBackgroundOptions({
                color,
              });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export function QualitySettings({
  setSize,
  size,
  imageFormat,
  setImageFormat,
  errorCorrectionLevel,
  setErrorCorrectionLevel,
}) {
  const { t } = useTranslation("workspace");

  let errorCorrection: number = 0;
  switch (errorCorrectionLevel) {
    case "L":
      errorCorrection = 7;
      break;
    case "M":
      errorCorrection = 15;
      break;
    case "Q":
      errorCorrection = 25;
      break;
    case "H":
      errorCorrection = 30;
      break;
    default:
      break;
  }

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Quality
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="error-correction-level"
            label="Code complexity"
            value={errorCorrectionLevel}
            onChange={(event) => {
              const errCorrLvl = event.target.value as ErrorCorrectionLevel;
              setErrorCorrectionLevel(errCorrLvl);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="L">{t("design.low")}</option>
            <option value="M">{t("design.medium")}</option>
            <option value="Q">{t("design.high")}</option>
            <option value="H">{t("design.very-high")}</option>
          </TextField>
          <Typography variant="body2" color="textSecondary" className="mt-2">
            {t("design.error-correction", { errorCorrection })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="format"
            label="Format"
            value={imageFormat}
            onChange={(event) => {
              setImageFormat(event.target.value);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="SVG">SVG</option>
            <option value="PNG">PNG</option>
            <option value="JPEG">JPEG</option>
            <option value="WEBP">WEBP</option>
          </TextField>
          <Typography variant="body2" color="textSecondary" className="mt-2">
            This is the format that will be used when you download the QR code.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography id="size-slider" gutterBottom>
            Size
          </Typography>
          <Slider
            aria-label="Meters"
            defaultValue={size || 0}
            onChange={(event: Event, newValue: number | number[]) => {
              setSize(newValue as number);
            }}
            getAriaValueText={(value: number) => `${value}px`}
            valueLabelDisplay="auto"
            // shiftStep={25}
            // step={25}
            step={8}
            marks={marks}
            min={256}
            max={2048}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const marks = [
  {
    value: 512,
    label: "512px",
  },
  {
    value: 1024,
    label: "1024m",
  },
  {
    value: 1536,
    label: "1536m",
  },
];

export function Presets({ preset, setPreset }) {
  const { t } = useTranslation("workspace");

  const handleOptionChange = (event) => {
    setPreset(event.target.value);
  };

  return (
    <ShapePresets preset={preset} handleOptionChange={handleOptionChange} />
  );
}

export function ShapePresets({ preset, handleOptionChange }) {
  const { t } = useTranslation("workspace");

  return (
    <React.Fragment>
      <Typography component="h1" variant="h5">
        Presets
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <FormGroup>
        <FormControlLabel
          control={
            <Radio
              checked={preset === "classic"}
              onChange={handleOptionChange}
              value={"classic"}
            />
          }
          label={"Classic"}
        />
        <Typography component="p" sx={{ color: "grey" }}>
          {t("design.classic-desc")}
        </Typography>
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Radio
              checked={preset === "branded"}
              onChange={handleOptionChange}
              value={"branded"}
            />
          }
          label={"Branded"}
        />
        <Typography component="p" sx={{ color: "grey" }}>
          {t("design.branded-desc")}
        </Typography>
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Radio
              checked={preset === "rounded"}
              onChange={handleOptionChange}
              value={"rounded"}
            />
          }
          label={"Rounded"}
        />
        <Typography component="p" sx={{ color: "grey" }}>
          {t("design.rounded-desc")}
        </Typography>
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Radio
              checked={preset === "dotted"}
              onChange={handleOptionChange}
              value={"dotted"}
            />
          }
          label={"Dotted"}
        />
        <Typography component="p" sx={{ color: "grey" }}>
          {t("design.dotted-desc")}
        </Typography>
      </FormGroup>
    </React.Fragment>
  );
}

export function ShapeSettings({ shape, setShape }) {
  const { t } = useTranslation("workspace");

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Base
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="dots-options-type"
            label={t("design.shape")}
            value={shape}
            onChange={(event) => {
              setShape(event.target.value);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="SQUARE">Square</option>
            <option value="CIRCLE">Circle</option>
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
}

export function DotsSettings({ dotsOptions, setDotsOptions }) {
  const { t } = useTranslation("workspace");

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Dots
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="dots-options-type"
            label={t("design.shape")}
            value={dotsOptions.type}
            onChange={(event) => {
              const type = event.target.value as DotType;
              setDotsOptions({
                color: dotsOptions.color,
                type,
              });
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="square">{t("design.square")}</option>
            <option value="dots">{t("design.dots")}</option>
            <option value="rounded">{t("design.rounded")}</option>
            <option value="extra-rounded">{t("design.extra-rounded")}</option>
            <option value="classy">{t("design.classy")}</option>
            <option value="classy-rounded">{t("design.classy-rounded")}</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type="color"
            fullWidth
            variant="outlined"
            id="dots-options-color"
            label={t("design.color")}
            value={dotsOptions.color}
            onChange={(event) => {
              const color = event.target.value;
              setDotsOptions({
                color,
                type: dotsOptions.type,
              });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export function DropzoneHelp() {
  const { t } = useTranslation("workspace");

  return (
    <React.Fragment>
      <svg
        className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
        />
      </svg>
      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
        <span className="font-semibold">{t("design.click-to-upload")}</span>{" "}
        {t("design.or-drag-and-drop")}
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-400">
        {t("design.image-formats")}
      </p>
    </React.Fragment>
  );
}

export function DropzoneDone({ uploadedImage }) {
  const { t } = useTranslation("workspace");
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (uploadedImage) {
      const objectURL = URL.createObjectURL(uploadedImage);
      setImageSrc(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [uploadedImage]);

  return (
    <React.Fragment>
      <img src={imageSrc} alt="uploaded qr code" width="30px" />
      <p className="mb-2 text-sm text-primary text-center">
        <span className="font-semibold">{t("design.image-uploaded")}</span>
        <br />
        {t("design.click-to-replace")}
      </p>
      <p className="text-xs text-gray-500">{t("design.image-formats")}</p>
    </React.Fragment>
  );
}

export function ImageDropzone({ setInputImage, inputImage }) {
  const onDrop = React.useCallback((acceptedFiles) => {
    setInputImage(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className="flex items-center justify-center w-full"
      {...getRootProps()}
    >
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-verylightgrey border-dashed border-greyish rounded-lg cursor-pointer bg-white hover:bg-lightgrey dark:bg-darkgrey dark:hover:bg-darker-grey dark:border-greyish dark:hover:border-darkgrey"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          {inputImage ? (
            <DropzoneDone uploadedImage={inputImage} />
          ) : (
            <DropzoneHelp />
          )}
        </div>
        <input
          {...getInputProps()}
          id="dropzone-file"
          onChange={(event) => {
            const files = event?.target?.files;
            const file = files ? files[0] : null;
            setInputImage(file);
          }}
          type="file"
          className="hidden"
        />
      </label>
    </div>
  );
}

export function ImageSettings({
  image,
  setImage,
  inputImage,
  setInputImage,
  imageSize,
  setImageSize,
  hideBackgroundDots,
  setHideBackroundDots,
}) {
  const { t } = useTranslation("workspace");

  useEffect(() => {
    if (inputImage) {
      const objectURL = URL.createObjectURL(inputImage);
      setImage(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [inputImage]);

  const removeImage = (e) => {
    e.preventDefault();
    setInputImage(undefined);
    setImage(null);
  };

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Image
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("design.file")}
          </Typography>
          <ImageDropzone
            setInputImage={setInputImage}
            inputImage={inputImage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="image-size"
            label={t("design.size")}
            value={imageSize}
            onChange={(event) => {
              const size = event.target.value;
              setImageSize(size);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="0.2">{t("design.very-small")}</option>
            <option value="0.4">{t("design.small")}</option>
            <option value="0.6">{t("design.medium")}</option>
            <option value="1.0">{t("design.big")}</option>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hideBackgroundDots}
                onChange={(event) => {
                  setHideBackroundDots(event.target.checked);
                }}
                name="hide-background-dots"
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                {t("design.hide-background")}
              </Typography>
            }
          />
          <Grid item xs={12}>
            <Typography variant="caption">
              {t("design.hide-background-desc")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {image && (
            <Button variant="contained" color="error" onClick={removeImage}>
              {t("design.remove-image")}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

import LoadingButton from "@/components/loading-button";
import { entrypointURL } from "@/helpers";
import { blobToExtension, findAndTranslateErrors } from "@/helpers/format";
import QRCodeStyling, {
  CornerDotType,
  CornerSquareType,
  DotType,
  ErrorCorrectionLevel,
} from "qr-code-styling";

const DEFAULT_QRCODE_URL = "https://linkbreakers.com";

const qrGen = new QRCodeStyling({
  width: 300,
  height: 300,
  // type: "svg",
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20,
  },
});

export function QrCode({
  target,
  getBlob,
  blobCallback,
  imageFormat,
  image,
  imageSize,
  dotsOptions,
  cornersSquareOptions,
  cornersDotOptions,
  backgroundOptions,
  shape,
  width,
  height,
  errorCorrectionLevel,
  hideBackgroundDots,
}) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [update, setUpdate] = React.useState(false);

  useEffect(() => {
    if (ref.current) {
      qrGen.append(ref.current);
    }
  }, []);

  useEffect(() => {
    setUpdate(true);
  }, [
    target,
    image,
    dotsOptions,
    cornersSquareOptions,
    cornersDotOptions,
    backgroundOptions,
    width,
    height,
    shape,
    errorCorrectionLevel,
    imageSize,
    hideBackgroundDots,
  ]);

  useEffect(() => {
    if (update) {
      qrGen.update({
        data: target,
        image,
        dotsOptions,
        cornersSquareOptions,
        cornersDotOptions,
        backgroundOptions,
        width,
        height,
        shape: shape.toLowerCase(),
        qrOptions: { errorCorrectionLevel },
        imageOptions: { imageSize, hideBackgroundDots },
      });
      setUpdate(false);
    }
  }, [update]);

  useEffect(() => {
    if (getBlob) {
      qrGen.getRawData(imageFormat.toLowerCase()).then((data) => {
        blobCallback(data);
      });
    }
  }, [getBlob, imageFormat]);

  return (
    <div>
      {/* we need this height for when there's no image inside */}
      <div style={{ minHeight: "300px" }} ref={ref} />
    </div>
  );
}
