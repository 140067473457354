import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { eventBusEmit } from "@/helpers/event-bus";
import { assignPathWith } from "@/helpers/navigation";
import { Link, Workspace } from "@/interfaces";
import { gql, useQuery } from "@apollo/client";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Title from "../components/title";

const GET_MYSELF_ORGANIZATION = gql`
  query MyWorkspace {
    MyWorkspace {
      id
      links {
        id
        name
        designType
        destination
        qrcodeSignedURL
      }
      linkSetting {
        id
        dataCollection
      }
    }
  }
`;

export default function LinksQRCode() {
  const { t } = useTranslation(["workspace", "misc"]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [updateLinkSetting, setUpdateLinkSetting] = useState<boolean>(false);
  const navigate = useNavigate();

  const [workspace, setWorkspace] = useState<Workspace>({
    id: null,
  });
  const [links, setLinks] = useState<Link[]>([]);
  const getMyWorkspace = useQuery(GET_MYSELF_ORGANIZATION);

  useEffect(() => {
    if (error) {
      eventBusEmit({ type: "form-error", payload: error });
      setError("");
      return;
    }

    if (success)
      eventBusEmit({
        type: "form-success",
        payload: t("settings.edit.updated"),
      });
    setSuccess(false);
  }, [success, error]);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("links.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  if (getMyWorkspace.loading) return <Loading />;
  if (getMyWorkspace.data) {
    if (workspace.id === null) setWorkspace(getMyWorkspace.data.MyWorkspace);

    const workspaceLinks = getMyWorkspace.data.MyWorkspace.links;
    if (links.length === 0 && workspaceLinks && workspaceLinks.length !== 0) {
      setLinks(workspaceLinks);
    }
  }
  if (getMyWorkspace.error) {
    return <PartialError error={t("links.qrcode-loading-error")} />;
  }

  const addLink = (event: React.MouseEvent) => {
    event.preventDefault();

    assignPathWith(navigate, "/workspace/links/new");
  };

  const NewLinkButton = () => {
    if (
      !permitAccess({
        role: getRole(),
        resource: Resource.DASHBOARD,
        permission: Permission.CREATE,
      })
    )
      return <></>;

    return (
      <Button
        className="w-auto float-right"
        type="submit"
        startIcon={<AddLocationIcon />}
        sx={{ textTransform: "none" }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={addLink}
      >
        {t("links.add-new-link-submit")}
      </Button>
    );
  };

  return (
    <Grid container spacing={3}>
      {/* Search */}
      <Grid item sx={{ textAlign: "left", flexGrow: 1 }}>
        <Box id="filter-panel" />
      </Grid>
      <Grid item xs={12} sm={5} lg={4} className="relative">
        <NewLinkButton />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container>
          {/* Members */}
          <Grid item xs={12} sm={7} lg={8}>
            <Title>{t("links.qrcode-title")}</Title>
            <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
              {t("links.qrcode-description")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinksList links={links} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <LinkSettings
              fetchedLinkSetting={getMyWorkspace.data.MyWorkspace.linkSetting}
              setUpdateLinkSetting={setUpdateLinkSetting}
              updateLinkSetting={updateLinkSetting}
              setError={setError}
              setSuccess={setSuccess}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { UpgradeButton } from "@/components/upgrade-button";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { LinkSettings } from "../settings";
import LinksList from "./list";
