import { Copyright } from "@/components/copyright";
import { FormError } from "@/components/form";
import PartialLoading from "@/components/partial-loading";
import { assignPathWith } from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { gql, useMutation, useQuery } from "@apollo/client";
import BackHandIcon from "@mui/icons-material/BackHand";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UPDATE_MYSELF = gql`
  mutation UpdateMyself($email: String!) {
    UpdateMyself(email: $email) {
      id
    }
  }
`;

const GET_MY_ORGANIZATION = gql`
  query MyWorkspace {
    MyWorkspace {
      id
      name
    }
  }
`;

export function UpdateButton({ email }) {
  const { t } = useTranslation("myself");
  const navigate = useNavigate();
  const [mutationUpdateMyself, { data, loading, error }] =
    useMutation(UPDATE_MYSELF);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    mutationUpdateMyself({
      variables: { email },
    });
  };

  if (error) {
    return <FormError error={t("email.error-on-creation")} sx={{ mb: 3 }} />;
  }

  if (loading) {
    return <PartialLoading />;
  }

  if (data) {
    assignPathWith(navigate, "/workspace/dashboard/live");
    return <PartialLoading />;
  }

  const formCompleted = email.length > 0;

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mb: 2 }}
      onClick={handleSubmit}
      disabled={!formCompleted}
    >
      Set email now
    </Button>
  );
}

export default function WorkspaceSettingsLocation() {
  const { t } = useTranslation("myself");
  const [email, setPassword] = useState<string>("");
  const [workspace, setWorkspace] = useState<{
    id: null | string;
    slug: null | string;
    name: null | string;
  }>({ id: null, slug: null, name: null });
  const { loading, error, data } = useQuery(GET_MY_ORGANIZATION);

  useEffect(() => {
    if (data) {
      if (workspace.id === null) setWorkspace(data.MyWorkspace);
    }
  }, [data, error, loading]);

  if (loading || !workspace) return <PartialLoading />;
  if (error) {
    return <NotFound error={error} />;
  }

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="xs"
        className="bg-white p-3 rounded-xl mt-10 shadow-md"
      >
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <BackHandIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("email.title")}
          </Typography>
          <Typography variant="subtitle1">{t("email.subtitle")}</Typography>
          <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <TextField
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                  id="email"
                  label={t("email.label")}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <UpdateButton email={email} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
