import { rawURLAssign } from "@/helpers/navigation";
import { gql, useQuery } from "@apollo/client";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTranslation } from "react-i18next";

const GET_MY_ORGANIZATION = gql`
  query MyWorkspace {
    MyWorkspace {
      id
      name
      subscriptionType
    }
  }
`;

export const UpgradeButton = () => {
  const { t } = useTranslation("billing");
  const { loading, error, data } = useQuery(GET_MY_ORGANIZATION);

  if (loading || error) {
    return <></>;
  }

  if (data?.MyWorkspace?.subscriptionType === "FREE_FOREVER") {
    return (
      <div
        className="cursor-pointer text-sm truncate"
        onClick={() => {
          rawURLAssign("/workspace/subscription");
        }}
      >
        You're on an unlimited premium plan trial. We'll be in touch!{" "}
        <AutoAwesomeIcon className="text-sm" />{" "}
      </div>
    );
  } else if (data?.MyWorkspace?.subscriptionType === "FREE_TRIAL") {
    return (
      <div
        className="cursor-pointer text-sm truncate"
        onClick={() => {
          rawURLAssign("/workspace/subscription");
        }}
      >
        You're on a 2-week premium plan trial. Already convinced?{" "}
        <a className="text-white underline">Upgrade now!</a>
        <AutoAwesomeIcon className="text-sm" />{" "}
      </div>
    );
  } else if (!data?.MyWorkspace?.subscriptionType) {
    return (
      <div
        className="cursor-pointer text-sm truncate"
        onClick={() => {
          rawURLAssign("/workspace/subscription");
        }}
      >
        You're currently on the free plan.{" "}
        <a className="text-white underline">Upgrade</a> to unlock more features.{" "}
        <AutoAwesomeIcon className="text-sm" />{" "}
      </div>
    );
  }

  return <></>;
};
