import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { UpgradeButton } from "@/components/upgrade-button";
import { eventBusEmit } from "@/helpers/event-bus";
import { Device } from "@/interfaces";
import { gql, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DevicesList from "./list";

const GET_MEMBER = gql`
  query GetMember($id: UUID!) {
    GetMember(id: $id) {
      id
      firstName
      lastName
      devices {
        id
        deviceBrand
        deviceType
        deviceName
        deviceMemory
        deviceConcurrency
        GPU
        screenWidth
        screenHeight
        screenRatio
        browserName
        browserVersion
        platformName
        platformVersion
        touchScreen
        networkConnection
        networkBandwidth
        networkRTT
        userAgent
        IPAddress
        applicationLanguage
        timezone
        country
        lastUsedAt
      }
    }
  }
`;

export default function Member() {
  const { t } = useTranslation(["workspace", "misc"]);

  const { memberID } = useParams();
  const { data, error, loading } = useQuery(GET_MEMBER, {
    variables: { id: memberID },
    fetchPolicy: "no-cache",
  });
  const [member, setMember] = useState<{
    id: string;
    firstName: string;
    lastName: string;
  }>({ id: "", firstName: "", lastName: "" });

  const [devices, setDevices] = useState<Device[]>([]);

  useEffect(() => {
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (data) {
      setDevices(data.GetMember.devices);
      setMember(data.GetMember);
      if (data.GetMember.firstName && data.GetMember.lastName) {
        const pageName: string = `${data.GetMember.firstName} ${data.GetMember.lastName}`;
        eventBusEmit({ type: "page-name", payload: pageName });
      }

      eventBusEmit({ type: "right-menu", payload: "" });
    }
  }, [data]);

  useEffect(() => {
    if (data) setMember(data.GetMember);
  }, [data, devices]);

  if (error)
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  if (loading) return <Loading />;

  return (
    <React.Fragment>
      <Grid container spacing={0} justifyContent="flex-end">
        <Grid item xs={12}>
          <DevicesList devices={devices} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
