import { GridWithSearch } from "@/components/data-table";
import { QrcodeImage } from "@/components/qr-code";
import { downloadImage, entrypointURL } from "@/helpers";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import { rawURLAssign } from "@/helpers/navigation";
import { Link, Member } from "@/interfaces";
import { tableContainerSx } from "@/theme";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  Tooltip,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

export function List({ links }) {
  const { i18n, t } = useTranslation("workspace");

  const LinkDesignCell = ({ link }: { link: Link }) => {
    if (link.designType === "BASIC") {
      return (
        <Tooltip title={t("links.design-basic-tooltip")} placement="top">
          <Chip icon={<QrCodeIcon />} label={t("links.design-basic")} />
        </Tooltip>
      );
    } else if (link.designType === "CUSTOM") {
      return (
        <Tooltip
          title="You customized the design of this QR Code"
          placement="top"
        >
          <Chip icon={<AutoAwesomeIcon />} label="Custom" />
        </Tooltip>
      );
    } else if (link.designType === "AI") {
      return (
        <Tooltip title={"AI"} placement="top">
          <Chip icon={<AutoModeIcon />} label={t("links.design-moving")} />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "qrcode",
      flex: 1,
      headerName: t("links.list.qrcode"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <QrcodeImage
            image={params.row.qrcodeSignedURL}
            clickToDownload={false}
            size="thumbnail"
          />
        );
      },
    },
    {
      field: "name",
      flex: 1,
      headerName: t("members.list.name"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return params.row.name || "-";
      },
      valueGetter: (_, row) => {
        return row.name;
      },
    },
    {
      field: "destination",
      flex: 1,
      headerName: t("links.list.destination"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return rows.destination;
      },
      valueGetter: (_, row) => {
        return row.destination;
      },
    },
    {
      field: "design-type",
      flex: 1,
      headerName: t("links.list.design"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <LinkDesignCell link={params.row} />;
      },
      valueGetter: (_, row) => {
        return row.designType;
      },
    },
    {
      field: "actions",
      flex: 0.6,
      headerName: "",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ActionMore link={params.row} />;
      },
    },
  ];

  const rows = links;
  return (
    <GridWithSearch columns={columns} rows={rows} i18n={i18n} rowHeight={80} />
  );
}

export function ActionMore({ link }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (link: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(link.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label="More"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuAction link={link} handleClose={handleClose} />
      </Menu>
    </React.Fragment>
  );
}

export function MenuAction({ link, handleClose }) {
  const { t } = useTranslation("workspace");

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          handleClose();
          downloadImage(link.qrcodeSignedURL);
        }}
      >
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>{t("links.download-qrcode")}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          handleClose();
          rawURLAssign(`/workspace/dashboard/links/${link.id}/edit`);
        }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>{t("links.edit-qrcode")}</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          handleClose();
          rawURLAssign(`/workspace/dashboard/links/${link.id}/edit/design`);
        }}
      >
        <ListItemIcon>
          <GestureIcon />
        </ListItemIcon>
        <ListItemText>{t("links.design-qrcode")}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.READ,
          })
        }
        onClick={() => {
          handleClose();
          const trackingLink = entrypointURL(link);
          navigator.clipboard.writeText(trackingLink);
          eventBusEmit({
            type: "form-success",
            payload: "Tracking link copied to your clipboard!",
          });
        }}
      >
        <ListItemIcon>
          <ContentCopy />
        </ListItemIcon>
        <ListItemText>Copy tracking link</ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

import { eventBusEmit } from "@/helpers/event-bus";
import ContentCopy from "@mui/icons-material/ContentCopy";
import GestureIcon from "@mui/icons-material/Gesture";

export default function LinksList({ links }) {
  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={tableContainerSx}>
        <List links={links} />
      </TableContainer>
    </React.Fragment>
  );
}
