import { Copyright } from "@/components/copyright";
import { FormError } from "@/components/form";
import GlobalLoading from "@/components/global-loading";
import GlobalSmallLoading from "@/components/global-small-loading";
import LoadingButton from "@/components/loading-button";
import { findAndTranslateErrors } from "@/helpers/format";
import { getParams, rawURLReplace } from "@/helpers/navigation";
import { Link } from "@/interfaces";
import NotFound from "@/pages/not-found";
import { gql, useMutation, useQuery } from "@apollo/client";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CREATE_LINK = gql`
  mutation CreateLink($name: String, $destination: String!) {
    CreateLink(name: $name, destination: $destination) {
      id
    }
  }
`;

const GET_MYSELF_ORGANIZATION = gql`
  query MyWorkspace {
    MyWorkspace {
      id
      name
    }
  }
`;

export function CreatePrimaryLink({
  name,
  destination,
  setLink,
  setError,
  disabled,
}) {
  const { t } = useTranslation("workspace");

  const [mutationCreateLink, { data, loading, error }] =
    useMutation(CREATE_LINK);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    mutationCreateLink({
      variables: { name, destination },
    });
  };

  useEffect(() => {
    if (error) {
      setError(findAndTranslateErrors({ error, t }));
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setLink(data.CreateLink);
    }
  }, [data]);

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      onClick={handleSubmit}
      text={t("links.create.submit")}
      fullWidth
    />
  );
}

export default function CreateLink() {
  const { t } = useTranslation(["workspace", "misc"]);
  const params = getParams();

  const [error, setError] = useState<string>("");
  const [link, setLink] = useState<Link | null>();
  const [workspace, setWorkspace] = useState<{
    id: null | string;
    slug: null | string;
    name: null | string;
  }>({ id: null, slug: null, name: null });
  const [name, setLinkName] = useState<string>("");
  const [destination, setLinkDestination] = useState<string>(
    params.get("destination") || ""
  );
  const [primary, setPrimary] = useState<boolean>(false);
  const getMyWorkspace = useQuery(GET_MYSELF_ORGANIZATION);

  useEffect(() => {
    if (getMyWorkspace.data) {
      if (workspace.id === null) setWorkspace(getMyWorkspace.data.MyWorkspace);
    }
  }, [getMyWorkspace]);

  useEffect(() => {
    if (name === "" && workspace.name) {
      const params = getParams();
      if (params.get("name")) {
        setLinkName(String(params.get("name")));
      }
    }
  }, [workspace]);

  useEffect(() => {
    const params = getParams();
    setPrimary(String(params.get("primary")) === "true");
  }, []);

  // I had to actually make a whole "link" state because inside the component
  // it would crash completely and have infinite loop redirect on iPad
  if (link) {
    rawURLReplace(`/workspace/links/${link.id}/design?primary=${primary}`);
    return <GlobalSmallLoading />;
  }

  if (getMyWorkspace.loading || !workspace) return <GlobalLoading />;
  if (getMyWorkspace.error) {
    return <NotFound error={getMyWorkspace.error} />;
  }

  let wsName: string | null = workspace.name;
  if (!wsName) {
    wsName = "your workspace";
  }

  let title: React.ReactElement;
  let subtitle: React.ReactElement;
  if (primary) {
    title = (
      <Typography component="h1" variant="h5">
        {t("links.create.set-primary-basic-title", {
          name: wsName,
        })}
      </Typography>
    );
    subtitle = (
      <Typography variant="subtitle1" className="m-auto text-center">
        {t("links.create.set-primary-basic-description")}
      </Typography>
    );
  } else {
    title = (
      <Typography component="h1" variant="h5" className="m-auto text-center">
        {t("links.create.set-basic-title", { name: wsName })}
      </Typography>
    );

    subtitle = (
      <Typography variant="subtitle1" className="m-auto text-center">
        {t("links.create.set-basic-description")}
      </Typography>
    );
  }

  const formCompleted = destination.length > 0;
  const disabled = !formCompleted;

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="sm"
        className="bg-white p-3 rounded-xl mt-10 shadow-md"
      >
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <QrCodeIcon />
          </Avatar>
          {title}
          {subtitle}
          <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
            <FormError error={error} sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="destination"
                  value={destination}
                  onChange={(e) => setLinkDestination(e.target.value)}
                  required
                  fullWidth
                  id="linkDestination"
                  placeholder="https://example.com"
                  autoComplete="off"
                  label={t("links.create.link-destination")}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  value={name}
                  onChange={(e) => setLinkName(e.target.value)}
                  fullWidth
                  id="linkName"
                  autoComplete="off"
                  label={t("links.create.link-name")}
                />
              </Grid>
              <Grid item xs={12}>
                <CreatePrimaryLink
                  name={name}
                  destination={destination}
                  disabled={disabled}
                  setError={setError}
                  setLink={setLink}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
