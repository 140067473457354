import AstroWondering from "@/components/astro-wondering";
import { FormError, FormSuccess } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import PartialLoading from "@/components/partial-loading";
import { PhoneField } from "@/components/phone-field";
import { UpgradeButton } from "@/components/upgrade-button";
import { eventBusEmit } from "@/helpers/event-bus";
import { camelToKebab } from "@/helpers/format";
import { gql, useMutation } from "@apollo/client";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../components/title";

const IMPORT_MEMBER = gql`
  mutation ImportMember(
    $phone: String
    $email: String
    $firstName: String
    $lastName: String
  ) {
    ImportMember(
      phone: $phone
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      id
    }
  }
`;

const allFields: string[] = ["email", "phone", "firstName", "lastName"];
const defaultSelectedFields: string[] = ["email", "firstName", "lastName"];
const defaultEmptyEntries = 1;
const minPhoneLength = 4;

interface ValueFields {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
}

const addItemAtIndex = (setter, index, newItem) => {
  setter((prevArray) => {
    const newArray = [...prevArray];
    const existingIndex = prevArray[index] !== undefined;

    if (existingIndex) {
      // If the item already exists, replace it
      newArray[index] = newItem;
    } else {
      // If the item doesn't exist, add it at the specified index
      newArray.splice(index, 0, newItem);
    }

    return newArray;
  });
};

const removeItemAtIndex = (setter, indexToRemove) => {
  setter((prevArray) => {
    const newArray = [...prevArray];
    const existingIndex =
      indexToRemove >= 0 && indexToRemove < prevArray.length;

    if (existingIndex) {
      newArray.splice(indexToRemove, 1); // Remove the item at the specified index
    }

    return newArray;
  });
};

const removeItem = (setter, label) => {
  setter((prev) => prev.filter((item) => item !== label));
};

const addItem = (setter, label) => {
  setter((prev) => [...prev, label]);
};

export function MemberSelectedFields({ selectedFields, setSelectedFields }) {
  const { t } = useTranslation("workspace");

  return (
    <Paper sx={{ p: 2 }}>
      {allFields.map((field, key) => {
        return (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                name={field}
                checked={selectedFields.includes(field)}
                onChange={(event) => {
                  if (event.target.checked) {
                    addItem(setSelectedFields, event.target.name);
                  } else {
                    removeItem(setSelectedFields, event.target.name);
                  }
                }}
              />
            }
            label={t(`members.create.${camelToKebab(field)}`)}
          />
        );
      })}
    </Paper>
  );
}

const filledIn = (valFlds) => {
  return (
    valFlds.phone.length >= minPhoneLength ||
    valFlds.email.length > 0 ||
    valFlds.firstName.length > 0 ||
    valFlds.lastName.length > 0
  );
};

export function MemberEntry({
  currentKey,
  importStarted,
  setImportsCompleted,
  setImportList,
  selectedFields,
}) {
  const { t } = useTranslation("workspace");
  const [mutationImportMember, { data, loading, error }] =
    useMutation(IMPORT_MEMBER);

  const createMember = () => {
    let {
      email,
      firstName,
      lastName,
    }: {
      email: string | null;
      firstName: string | null;
      lastName: string | null;
    } = valueFields;

    let phone: string | null = null;
    if (
      valueFields.phone.length >= minPhoneLength &&
      selectedFields.includes("phone")
    ) {
      phone = valueFields.phone;
    }

    if (!email || !selectedFields.includes("email")) email = null;
    if (!firstName || !selectedFields.includes("firstName")) firstName = null;
    if (!lastName || !selectedFields.includes("lastName")) lastName = null;

    mutationImportMember({
      variables: {
        phone,
        email,
        firstName,
        lastName,
      },
    });
  };

  const [valueFields, setValueFields] = useState<ValueFields>({
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [alreadySucceeded, setAlreadySucceeded] = useState<boolean>(false);
  const [removed, setRemoved] = useState<boolean>(false);

  useEffect(() => {
    if (removed) {
      // we should set to null
      // so it's not sent out
      setValueFields({
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
      });
    }
  }, [removed]);

  useEffect(() => {
    if (data) {
      setAlreadySucceeded(true);
    }

    if (data || error) {
      // NOTE: we should make sure this is launched only once
      setImportsCompleted((prev) => prev + 1);
    }
  }, [data, error]);

  useEffect(() => {
    addItemAtIndex(setImportList, currentKey, valueFields);
    //removeEmptyEntries(setImportList);
    // we add an item in setImportList only if we start filling it
    // it's useful to add new fields automatically
    if (!filledIn(valueFields)) {
      removeItemAtIndex(setImportList, currentKey);
    }
  }, [valueFields]);

  useEffect(() => {
    // if already succeeded, we don't need to try and create it again
    if (alreadySucceeded || removed) {
      setImportsCompleted((prev) => prev + 1);
    } else {
      if (filledIn(valueFields) && importStarted) {
        createMember();
      }
    }
  }, [importStarted]);

  const fields: string[] = allFields.filter((element) =>
    selectedFields.includes(element)
  );

  const TrashEntry = () => {
    return (
      <Tooltip title={t("members.import.delete-entry-tooltip")} placement="top">
        <IconButton
          color="secondary"
          aria-label={t("members.metrics-button")}
          onClick={() => {
            setRemoved(true);
          }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    );
  };

  if (removed) {
    return <></>;
  }

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 1 }}>
      {fields.map((field) => {
        return (
          <Grid item xs={12} lg={field === "phone" ? 3 : 2} key={field}>
            {field === "phone" ? (
              <PhoneField
                margin="normal"
                fullWidth
                id="phone"
                type="phone"
                value={valueFields["phone"]}
                disabled={alreadySucceeded}
                label={t("members.create.phone")}
                onChange={(phone) => {
                  setValueFields((prev) => ({
                    ...prev,
                    phone,
                  }));
                }}
                autoComplete="off"
              />
            ) : (
              <TextField
                label={t(`members.create.${camelToKebab(field)}`)}
                type={field}
                fullWidth
                autoComplete="off"
                name={field}
                disabled={alreadySucceeded}
                value={valueFields[field]}
                onChange={(e) => {
                  const fieldName = e.target.name;
                  const fieldValue = e.target.value;
                  setValueFields((prev) => ({
                    ...prev,
                    [fieldName]: fieldValue,
                  }));
                }}
              />
            )}
          </Grid>
        );
      })}
      <Grid item xs={12} lg={3} style={{ paddingLeft: 0 }}>
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <div>
            {filledIn(valueFields) && !alreadySucceeded ? (
              <TrashEntry />
            ) : (
              <div style={{ paddingLeft: "10px" }} />
            )}
          </div>
          <div>
            {" "}
            {error ? <FormError error={error} /> : <></>}
            {loading ? (
              <PartialLoading textAlign="left" paddingTop="0.5em" />
            ) : (
              <></>
            )}
            {data ? (
              <FormSuccess message={t("members.import.success")} />
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}

export default function ImportMembers() {
  const { t } = useTranslation(["workspace", "misc"]);
  const [selectedFields, setSelectedFields] = useState<string[]>(
    defaultSelectedFields
  );
  const [totalEntries, setTotalEntries] = useState<number>(defaultEmptyEntries);
  const [importStarted, setImportStarted] = useState<boolean>(false);
  const [importsCompleted, setImportsCompleted] = useState<number>(0);
  // i've overengineered this part so don't hesitate to remove if needed
  // it can be useful for batch mutation (all at once) because it contains all the fields
  // but for now we don't do batch because it's useless.
  const [importList, setImportList] = useState<[]>([]);

  const filledInImportList = importList.filter((valFlds) => filledIn(valFlds));

  useEffect(() => {
    eventBusEmit({
      type: "page-name",
      payload: t("members.team-members.import"),
    });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (importList.length === totalEntries) {
      setTotalEntries(totalEntries + 1);
    }
  }, [importList]);

  useEffect(() => {
    if (importsCompleted >= filledInImportList.length) {
      setImportStarted(false);
      setImportsCompleted(0);
    }
  }, [importsCompleted, totalEntries]);

  const minimalFields =
    selectedFields.includes("email") || selectedFields.includes("phone");

  useEffect(() => {
    // if we remove the main columns
    // we should reset it all
    if (!minimalFields) {
      setTotalEntries(defaultEmptyEntries);
      setImportList([]);
    }
  }, [selectedFields]);

  const startImporting = () => {
    setImportStarted(true);
  };

  const renderEntries = () => {
    const entries: JSX.Element[] = [];
    for (let key = 0; key < totalEntries; key++) {
      entries.push(
        <MemberEntry
          currentKey={key}
          importStarted={importStarted}
          setImportsCompleted={setImportsCompleted}
          selectedFields={selectedFields}
          setImportList={setImportList}
        />
      );
    }
    return entries;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Title>{t("members.import.title-fields")}</Title>
        <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
          {t("members.import.please-select-fields")}
        </Typography>
        <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
          <MemberSelectedFields
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
          />
        </Grid>

        <Divider variant="middle" sx={{ mb: 2 }} />

        <Title>{t("members.import.prepare-your-batch")}</Title>
        <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
          {t("members.import.fill-in-team-members")}
        </Typography>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            {!minimalFields ? (
              <Stack alignItems="center" textAlign="center">
                <Grid item xs={4} lg={4} sx={{ mb: 2 }}>
                  <AstroWondering />
                  <Typography>
                    {t("members.import.select-phone-or-email")}
                  </Typography>
                </Grid>
              </Stack>
            ) : (
              renderEntries()
            )}
          </Paper>
        </Grid>

        <Divider variant="middle" sx={{ mb: 2 }} />
        <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
          <LoadingButton
            loading={importStarted}
            disabled={
              importStarted ||
              importList.length === 0 ||
              filledInImportList.length === 0
            }
            onClick={startImporting}
            text={t("members.import.button")}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
