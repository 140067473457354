export function sleep(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function downloadImage(image) {
  const href: string | null = image;
  if (href === null) {
    return;
  }

  fetch(href)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "qrcode";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert("An error sorry"));
}

export function URLforHTMLExport({
  workspaceId,
  memberId,
  linkId,
  startDate,
  endDate,
  lang,
}) {
  const baseURL = import.meta.env.VITE_API_BASE_URL;
  if (!linkId) linkId = "";

  return `${baseURL}/exports/html/work-timesheet?workspace_id=${workspaceId}&member_id=${memberId}&link_id=${linkId}&start_date=${startDate}&end_date=${endDate}&lang=${lang}`;
}

export function entrypointURL(link: { id: string }): string {
  const baseURL = import.meta.env.VITE_API_BASE_URL;
  const redirectURL = `/scan/${link.id}`;

  return `${baseURL}${redirectURL}`;
}
