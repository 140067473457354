import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { UpgradeButton } from "@/components/upgrade-button";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { eventBusEmit } from "@/helpers/event-bus";
import { getRole } from "@/helpers/identity";
import { assignPathWith } from "@/helpers/navigation";
import MembersList from "@/pages/workspace/dashboard/members/list";
import { gql, useQuery } from "@apollo/client";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LIST_MEMBERS = gql`
  query ListMembers {
    ListMembers {
      id
      role
      updatedAt
      createdAt
      firstName
      lastName
      accessRevokedAt
      email
      phone
      lastSigninAt
      lastKnownIP
    }
  }
`;

export default function Members() {
  const { t } = useTranslation(["workspace", "misc"]);
  const navigate = useNavigate();

  const { data, error, loading } = useQuery(LIST_MEMBERS, {
    fetchPolicy: "cache-and-network",
  });
  const [members, setMembers] = useState<
    {
      id: string;
      createdAt: string;
      updatedAt: string;
      firstName: string;
      lastName: string;
      accessRevokedAt: string;
      email: string;
    }[]
  >([]);

  useEffect(() => {
    eventBusEmit({
      type: "page-name",
      payload: t("members.team-members.title"),
    });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  // to test react crash / bugs we can activate this
  // const test: any = {};
  // console.log(test.should.crash);

  useEffect(() => {
    if (data) setMembers(data.ListMembers);
  }, [data, members]);

  if (error)
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  if (loading) return <Loading />;

  const ImportButton = () => {
    if (
      !permitAccess({
        role: getRole(),
        resource: Resource.DASHBOARD,
        permission: Permission.CREATE,
      })
    )
      return <></>;

    return (
      <Button
        type="submit"
        startIcon={<PersonAddAlt1Icon />}
        sx={{ textTransform: "none" }}
        variant="contained"
        className="m-auto"
        color="primary"
        onClick={() => {
          assignPathWith(navigate, `/workspace/dashboard/members/import`);
        }}
      >
        {t("members.import-team-members-button")}
      </Button>
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={0} justifyContent="flex-end">
        {/* Search */}
        <Grid item sx={{ marginBottom: 2, textAlign: "left", flexGrow: 1 }}>
          <Box id="filter-panel" />
        </Grid>
        {/* Members */}
        <Grid
          item
          xs={12}
          sm={4}
          lg={3}
          xl={2}
          sx={{ marginBottom: 2, textAlign: "right" }}
        >
          <ImportButton />
        </Grid>
        <Grid item xs={12}>
          <MembersList members={members} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
