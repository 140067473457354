import { Event } from "@/interfaces";
import { getDateAndTime } from "./time";

export function arrayToCSV(data) {
  return data
    .map((row) =>
      row
        .map(String)
        .map((v) => v.replaceAll('"', '""'))
        .map((v) => `"${v}"`)
        .join(",")
    )
    .join("\r\n");
}

export function downloadCSV({ filename, data }) {
  downloadBlob(data, `${filename}.csv`, "text/csv;charset=utf-8;");
}

export function downloadBlob(content, filename, contentType) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

export function convertEventsToDataset(
  t,
  events: Event[]
): (string | number | true)[][] {
  const headerRow: string[] = [
    t("events.list.num"),
    t("events.list.id"),
    t("events.list.action"),
    t("events.list.scanned-at"),
    t("events.list.triggered-by"),
    t("events.list.link-id"),
    t("events.list.link-name"),
    t("events.list.link-destination"),
    t("events.list.link-design"),
    t("events.list.device-id"),
    t("events.list.device-name"),
    t("events.list.device-brand"),
    t("events.list.device-type"),
    t("events.list.device-memory"),
    t("events.list.device-concurrency"),
    t("events.list.screen-width"),
    t("events.list.screen-height"),
    t("events.list.screen-ratio"),
    t("events.list.gpu"),
    t("events.list.browser-name"),
    t("events.list.browser-version"),
    t("events.list.platform-name"),
    t("events.list.platform-version"),
    t("events.list.touch-screen"),
    t("events.list.network-connection"),
    t("events.list.network-bandwidth"),
    t("events.list.network-rtt"),
    t("events.list.user-agent"),
    t("events.list.ip-address"),
    t("events.list.application-language"),
    t("events.list.timezone"),
    t("events.list.country"),
    t("events.list.last-used-at"),
  ];

  const dataRows: (string | number | true)[][] = events.map((event, index) => {
    return [
      (index + 1).toString(),
      event.id || "",
      event.action || "",
      getDateAndTime(event.scannedAt) || "",
      event.triggeredBy || "",
      event.link?.id || "",
      event.link?.name || "",
      event.link?.destination || "",
      event.link?.designType || "",
      event.device?.id || "",
      event.device?.deviceName || "",
      event.device?.deviceBrand || "",
      event.device?.deviceType || "",
      event.device?.deviceMemory || "",
      event.device?.deviceConcurrency || "",
      event.device?.screenWidth || "",
      event.device?.screenHeight || "",
      event.device?.screenRatio || "",
      event.device?.GPU || "",
      event.device?.browserName || "",
      event.device?.browserVersion || "",
      event.device?.platformName || "",
      event.device?.platformVersion || "",
      event.device?.touchScreen || "",
      event.device?.networkConnection || "",
      event.device?.networkBandwidth || "",
      event.device?.networkRTT || "",
      event.device?.userAgent || "",
      event.device?.IPAddress || "",
      event.device?.applicationLanguage || "",
      event.device?.timezone || "",
      event.device?.country || "",
      getDateAndTime(event.device?.lastUsedAt) || "",
    ];
  });

  const dataset: (string | number | true)[][] = [headerRow, ...dataRows];

  return dataset;
}
