import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { UpgradeButton } from "@/components/upgrade-button";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { eventBusEmit } from "@/helpers/event-bus";
import { getRole } from "@/helpers/identity";
import { LinkSetting, Workspace } from "@/interfaces";
import Title from "@/pages/workspace/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);

const GET_MYSELF_ORGANIZATION = gql`
  query MyWorkspace {
    MyWorkspace {
      id
      name
      linkSetting {
        id
        dataCollection
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation UpdateWorkspace($name: String) {
    UpdateWorkspace(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_LINK_SETTING = gql`
  mutation UpdateLinkSetting($id: UUID!, $dataCollection: DataCollection) {
    UpdateLinkSetting(id: $id, dataCollection: $dataCollection) {
      id
    }
  }
`;

export default function Dashboard() {
  const { t } = useTranslation(["workspace", "misc"]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [updateWorkspaceSetting, setUpdateWorkspaceSetting] =
    useState<boolean>(false);

  const getMyWorkspace = useQuery(GET_MYSELF_ORGANIZATION);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("settings.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (error) {
      eventBusEmit({ type: "form-error", payload: error });
      setError("");
      return;
    }

    if (success) {
      eventBusEmit({
        type: "form-success",
        payload: t("settings.edit.updated"),
      });
      setSuccess(false);
    }
  }, [success, error]);

  if (getMyWorkspace.loading) return <Loading />;
  if (getMyWorkspace.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setUpdateWorkspaceSetting(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <WorkspaceSettings
            fetchedWorkspaceSetting={getMyWorkspace.data.MyWorkspace}
            setUpdateWorkspaceSetting={setUpdateWorkspaceSetting}
            updateWorkspaceSetting={updateWorkspaceSetting}
            setError={setError}
            setSuccess={setSuccess}
          />

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
            <Button
              disabled={
                !permitAccess({
                  role: getRole(),
                  resource: Resource.DASHBOARD,
                  permission: Permission.UPDATE,
                })
              }
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ mb: 2 }}
            >
              {t("settings.submit")}
            </Button>
          </Grid>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export function WorkspaceSettings({
  fetchedWorkspaceSetting,
  setUpdateWorkspaceSetting,
  updateWorkspaceSetting,
  setSuccess,
  setError,
}) {
  const { t } = useTranslation(["workspace", "misc"]);
  const [workspace, setWorkspace] = useState<Workspace>({
    id: null,
    name: null,
  });

  const [mutationUpdateWorkspace, { data, error }] =
    useMutation(UPDATE_ORGANIZATION);

  useEffect(() => {
    if (data) setSuccess(true);
    if (error) setError(error);
  }, [data, error]);

  useEffect(() => {
    if (fetchedWorkspaceSetting) setWorkspace(fetchedWorkspaceSetting);
  }, [fetchedWorkspaceSetting]);

  useEffect(() => {
    if (updateWorkspaceSetting) {
      mutationUpdateWorkspace({
        variables: {
          name: workspace.name,
        },
      });
      setUpdateWorkspaceSetting(false);
    }
  }, [updateWorkspaceSetting]);

  if (workspace.id === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Title>{t("workspace.title")}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t("workspace.description")}
      </Typography>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("workspace.entity-details")}
            description={t("workspace.entity-details-description")}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="workspace"
                  name="workspaceName"
                  value={workspace.name}
                  onChange={(event) => {
                    setWorkspace((prevSet: Workspace) => ({
                      ...prevSet,
                      name: event.target.value,
                    }));
                  }}
                  required
                  id="workspaceName"
                  label={t("workspace.name-field")}
                />
              </Grid>
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

export function LinkSettings({
  fetchedLinkSetting,
  setUpdateLinkSetting,
  updateLinkSetting,
  setSuccess,
  setError,
}) {
  const { t } = useTranslation(["workspace", "misc"]);
  const [linkSetting, setLinkSetting] = useState<LinkSetting>({
    id: null,
    dataCollection: "NONE",
  });
  const [mutationLinkSetting, { data, error }] =
    useMutation(UPDATE_LINK_SETTING);

  useEffect(() => {
    if (data) setSuccess(true);
    if (error) setError(error);
  }, [data, error]);

  useEffect(() => {
    if (updateLinkSetting) {
      // we need to convert all the nulls data that were defined here
      // and send them  on the network as zero (numbers)
      // so it can nullified on the Golang side
      mutationLinkSetting({
        variables: linkSetting,
      });
      setUpdateLinkSetting(false);
    }
  }, [updateLinkSetting]);

  useEffect(() => {
    if (fetchedLinkSetting) {
      setLinkSetting(fetchedLinkSetting);
    }
  }, [fetchedLinkSetting]);

  if (linkSetting.id === null) {
    return <></>;
  }

  const marks = [
    {
      value: 10,
      label: "10m",
    },
    {
      value: 100,
      label: "100m",
    },
    {
      value: 200,
      label: "200m",
    },
    {
      value: 300,
      label: "300m",
    },
  ];

  return (
    <React.Fragment>
      <Title>{t("link-setting.title")}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t("links.description")}
      </Typography>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("links.data-collection")}
            description={t("links.data-collection-description")}
            appOnly={true}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper sx={{ p: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Radio
                      checked={linkSetting.dataCollection === "NONE"}
                      onChange={() => {
                        setLinkSetting((prevSet: LinkSetting) => ({
                          ...prevSet,
                          dataCollection: "NONE",
                        }));
                        setUpdateLinkSetting(true);
                      }}
                    />
                  }
                  label={t("links.none-data-collection")}
                />
                <Typography component="p" sx={{ color: "grey" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("links.none-data-collection-info", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Typography>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Radio
                      checked={linkSetting.dataCollection === "BASIC"}
                      onChange={() => {
                        setLinkSetting((prevSet: LinkSetting) => ({
                          ...prevSet,
                          dataCollection: "BASIC",
                        }));
                        setUpdateLinkSetting(true);
                      }}
                    />
                  }
                  label={t("links.basic-data-collection")}
                />
                <Typography component="p" sx={{ color: "grey" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("links.basic-data-collection-info", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Typography>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Radio
                      checked={linkSetting.dataCollection === "ADVANCED"}
                      onChange={() => {
                        setLinkSetting((prevSet: LinkSetting) => ({
                          ...prevSet,
                          dataCollection: "ADVANCED",
                        }));
                        setUpdateLinkSetting(true);
                      }}
                    />
                  }
                  label={t("links.advanced-data-collection")}
                />
                <Typography component="p" sx={{ color: "grey" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("links.advanced-data-collection-info", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Typography>
              </FormGroup>
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

export function SettingDescription({ title, description, appOnly = false }) {
  const { t } = useTranslation(["workspace", "misc"]);

  const gdprChip = (
    <Tooltip title={t("workspace.app-only")} placement="top">
      <span className="bg-primary rounded-lg text-sm p-1 text-white">GDPR</span>
    </Tooltip>
  );
  return (
    <Grid item xs={12} md={4} sx={{ mb: 2, mt: 2 }}>
      <Typography component="h3" sx={{ fontWeight: "bold" }}>
        {title} {appOnly ? <>{gdprChip}</> : <></>}
      </Typography>
      <Typography component="p" sx={{ color: "grey" }}>
        {description}
      </Typography>
    </Grid>
  );
}
